import { Button } from "../../ui/button";
import Image from "next/image";
import Tag from "@/components/shared/Tag";
import dynamic from "next/dynamic";

const steps = [
  {
    number: "1",
    title: "Write and outline",
    image: "https://cdn-crayo.com/lp/public/stepOne.png",
    description: "Start with writing script using a prompt or using template suggestions.",
  },
  {
    number: "2",
    title: "Customize and style",
    image: "https://cdn-crayo.com/lp/public/stepTwo.png",
    description: "Select background video, voice and a music from free templates.",
  },
  {
    number: "3",
    title: "Finish & export",
    image: "https://cdn-crayo.com/lp/public/stepThree.png",
    description: "Choose export format and download generated videos, script or narration.",
  },
];

interface StepProps {
  number: string;
  title: string;
  image: string;
  description: string;
}

const Step = ({ number, title, image, description }: StepProps) => (
  <div className="space-y-2.5 rounded-[20px] border border-crayoBlue/20 bg-[#F9F9FF]">
    <div className="flex flex-row space-x-2.5 px-5 pt-5">
      <div className="relative flex h-8 w-8 items-center justify-center rounded-full border border-slate-300 bg-slate-200 bg-opacity-20 before:absolute before:inset-0 before:-z-10 before:rounded-full before:bg-gradient-to-br before:from-[#DFECFF] before:to-[#A8CBFF] before:p-[1.5px] before:content-['']">
        <p className="text-sm text-crayoBlue">{number}</p>
      </div>
      <p className="text-md font-ambit-bold text-[21px] md:text-lg">{title}</p>
    </div>
    <Image src={image} alt={`step${number}`} width={1000} height={222} className="w-full" />
    <div className="p-7">
      <p className="text-md font-ambit-medium text-center  text-sm leading-[130%] md:text-[18px]">
        {description}
      </p>
    </div>
  </div>
);

const HowItWorks = () => {
  return (
    <div className="flex flex-col items-center justify-between bg-white pb-16 text-center">
      <div className="flex flex-col items-center justify-between space-y-8 px-8 text-center md:space-y-12 md:px-44 md:py-24 2xl:px-96">
        <Tag label="HOW IT WORKS" icon="https://cdn-crayo.com/lp/public/iconLightbulb.png" />
        <div className="flex w-full flex-col space-y-2 px-4 md:w-2/3 md:space-y-4 md:px-0">
          <h3 className="text-[32px] leading-[140%] md:text-[48px]">
            Create viral clips in 3 steps.
          </h3>
          <h6 className="text-[18px] md:text-[24px]">
            Never manually stitch a clip, or generate subtitles by hand ever again.
          </h6>
        </div>
        <div className="flex flex-col space-x-0 space-y-6 md:flex-row md:space-x-10 md:space-y-0">
          {steps.map((step) => (
            <Step key={step.number} {...step} />
          ))}
        </div>
        <Button href="http://crayo.ai/dashboard" variant="default" className="py-4">
          Create an account
        </Button>
      </div>
    </div>
  );
};

export default HowItWorks;
